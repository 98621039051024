import React from "react";

import { PageProps } from "gatsby";
import SEO from "src/components/Seo";

const NotFoundPage: React.SFC<PageProps> = () => (
  <div>
    <SEO pageTitle="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
